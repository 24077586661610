<template>
  <div class="buildings-modify">
    <v-container class="buildings-modify__container" fluid>
      <entity-modify-header class="mb-10" :title="title" />

      <form-builder v-if="!isLoading" :schema="$options.schema" :initial-data="initialData" @submit="submit">
        <template #footer="{ valid }">
          <v-row>
            <v-col>
              <v-btn color="secondary" block class="primary--text" :to="backRoute">{{ $t('button.back') }}</v-btn>
            </v-col>
            <v-col>
              <v-btn type="submit" color="primary" block :disabled="!valid">{{ submitButtonText }}</v-btn>
            </v-col>
          </v-row>
        </template>
      </form-builder>
    </v-container>
  </div>
</template>

<script>
// Services
import realtyService from '@/services/realty';
import notificationService from '@/services/notification';
import analyticsService from '@/services/analytics';

// Schema
import { schema } from '@/schemas/building.schema';

// Components
import EntityModifyHeader from '@/components/EntityModifyHeader.vue';
import FormBuilder from '@/components/schema/FormBuilder.vue';

// Utils
import { createModelData } from '@/schemas/createModelData';

// Constants
import { PROJECTS, BUILDINGS_DETAILED } from '@/constants/routes';
import { CREATE_BUILDING, EDIT_BUILDING } from '@/constants/analyticsActions';

export default {
  name: 'BuildingsEdit',

  components: { EntityModifyHeader, FormBuilder },

  props: {
    isEdit: { type: Boolean, default: false },
  },

  data() {
    return {
      isLoading: false,
      initialData: createModelData(schema),
    };
  },

  computed: {
    submitButtonText() {
      return this.$t(this.isEdit ? 'button.edit_building' : 'button.add_building');
    },

    backRoute() {
      return { name: PROJECTS, query: { page: this.$route.query?.prevPage || 1 } };
    },

    title() {
      if (this.isEdit) {
        return this.$t('buildings.edit_building');
      }

      return this.$t('buildings.add_building');
    },
  },

  mounted() {
    if (this.isEdit) {
      this.isLoading = true;
      realtyService
        .getBuildingById(this.$route.params.id)
        .then(building => {
          this.initialData = building;
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  },

  methods: {
    submit(data) {
      if (this.$options.notificationItem) {
        notificationService.remove(this.$options.notificationItem);
      }

      const saveBuilding = this.isEdit ? realtyService.updateBuilding : realtyService.createBuilding;

      const saveData = {
        ...data,
        project: data.project.id,
        id: this.$route?.params?.id,
      };

      this.isLoading = true;
      analyticsService.track(this.isEdit ? EDIT_BUILDING : CREATE_BUILDING);
      // @todo Добавить обработку ошибок
      saveBuilding(saveData)
        .then(project => {
          const message = this.isEdit ? this.$t('building.building_edited') : this.$t('building.building_added');
          notificationService.success(message, 2000);

          this.$router.push({ name: BUILDINGS_DETAILED, params: { id: project?.id || this.$route.params.id } });
        })
        .catch(error => {
          this.$options.notificationItem = notificationService.error(
            `${this.$t('error.found_errors')} ${Object.keys(error?.response?.data).join(', ')}`
          );
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },

  notificationItem: null,
  schema,
};
</script>

<style lang="scss">
.buildings-modify {
  &__container {
    max-width: 548px !important;
  }
}
</style>
