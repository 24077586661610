/* eslint-disable import/prefer-default-export */
import { VIEW, FORM } from '@/constants/builder';
import { NONE } from '@/constants/value';

import * as schemaItemTypes from './schemaItemTypes';
import { rules } from './validationRules';

const dependentProperties = {
  NAME: 'name',
  PROJECT: 'project',
  ADDRESS: 'address',
};

/**
 * @type {Array<import('./schema').default>}
 */
export const schema = [
  {
    label: 'buildings.name',
    type: schemaItemTypes.STRING,
    prop: dependentProperties.NAME,
    rules: [rules.REQUIRED],
    visibility: [{ builderType: VIEW, value: NONE }],
  },
  {
    label: 'buildings.project_name',
    type: schemaItemTypes.PROJECT,
    prop: dependentProperties.PROJECT,
    rules: [rules.REQUIRED],
    // onUpdate: [
    //   {
    //     type: onUpdateEffects.SET_IF_PROPERTY_TRUE,
    //     to: dependentProperties.NAME,
    //     conditionProp: 'isEmpty',
    //     from: 'name',
    //   },
    // ],
  },
  {
    label: 'buildings.address',
    type: schemaItemTypes.STRING,
    prop: dependentProperties.ADDRESS,
  },
  {
    label: 'buildings.number_of_units',
    type: schemaItemTypes.NUMBER,
    prop: 'numberOfUnits',
    rules: [rules.REQUIRED],
    visibility: [{ builderType: FORM, value: NONE }],
  },
];
